<template>
  <div>
    <h1 class="ui-title-page">RealHub Careers</h1>
    <div class="triagl triagl-btm"></div>
    <section
      class="section-area section_slider-reviews bg-gray section-testimoials"
    >
      <h2 class="ui-title-block">Welcome</h2>
      <div class="ui-subtitle-block">Join our team</div>
      <div class="border-color border-color_small"></div>
      <div class="container">
        <div class="single-job-items mb-30">
          <div class="job-items">
            <div class="company-img">
              <router-link :to="{ name: 'careers.field-assistant' }"
                ><img src="/logo.png" alt="" height="40"
              /></router-link>
            </div>
            <div class="job-tittle">
              <router-link :to="{ name: 'careers.field-assistant' }"><h4>Field Assistant</h4></router-link>
              <ul>
                <li>On Demand</li>
                <li><i class="lni lni-map-marker"></i>Kenya</li>
                <li><i class="lni lni-hourglass"></i>Part Time</li>
                <li><i class="lni lni-hourglass"></i>Flexible Hours</li>
              </ul>
            </div>
          </div>
          <div class="items-link f-right">
            <router-link :to="{ name: 'careers.field-assistant' }">Apply Now</router-link>
            <!-- <span>7 hours ago</span> -->
          </div>
        </div>
        <div class="single-job-items mb-30 mt-2">
          <div class="job-items">
            <div class="company-img">
              <router-link :to="{ name: 'careers.relationship-manager' }"
                ><img src="/logo.png" alt="" height="40"
              /></router-link>
            </div>
            <div class="job-tittle">
              <router-link :to="{ name: 'careers.relationship-manager' }"><h4>Relationship Manager</h4></router-link>
              <ul>
                <li>On Demand</li>
                <li><i class="lni lni-map-marker"></i>Kenya</li>
                <li><i class="lni lni-hourglass"></i>Part Time</li>
                <li><i class="lni lni-hourglass"></i>Flexible Hours</li>
              </ul>
            </div>
          </div>
          <div class="items-link f-right">
            <router-link :to="{ name: 'careers.relationship-manager' }">Apply Now</router-link>
            <!-- <span>7 hours ago</span> -->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  computed: {
    contact() {
      return this.$store.getters.getContact;
    },
  },
};
</script>

<style scoped>
.single-job-items {
  padding: 36px 30px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  transition: 0.4s;
  background: #fff;
}

.single-job-items .job-items {
  display: flex;
  flex-wrap: wrap;
}

.single-job-items .company-img img {
  overflow: hidden;
  float: left;
  margin-right: 32px;
  z-index: 999;
}

.single-job-items .job-tittle a h4 {
  color: #28395a;
  font-size: 24px;
  text-align: left;
  transition: 0.4s;
}

.single-job-items .job-tittle ul li {
  display: inline-block;
  margin-right: 48px;
  font-size: 15px;
  color: #808080;
  line-height: 1.8;
}

.single-job-items .job-tittle ul li i {
  font-size: 14px;
  color: #bbbbbb;
  margin-right: 10px;
}

.items-link a {
  color: #8b92dd;
  display: block;
  border: 1px solid #8b92dd;
  border-radius: 30px;
  padding: 4px 33px;
  text-align: center;
  margin-bottom: 25px;
}

.items-link span {
  color: #454d72;
  display: block;
}

@media (max-width: 767px) {
  .single-job-items .job-tittle {
    padding-top: 25px;
  }

  .single-job-items .job-tittle ul li {
    margin-right: 0px;
    margin-bottom: 7px;
  }
}
</style>
